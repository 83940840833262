<template>
  <div class="article-create-container">
    <!-- 页面头部 -->
    <div class="page-header">
      <div class="header-content">
        <h1 class="page-title">创作中心</h1>
        <div class="page-actions">
          <el-button @click="previewArticle" type="info" plain>
            <i class="el-icon-view"></i> 预览
          </el-button>
          <el-button @click="saveAsDraft" type="warning" plain>
            <i class="el-icon-document"></i> 保存草稿
          </el-button>
          <el-button @click="handleSubmit" type="primary">
            <i class="el-icon-position"></i> 发布文章
          </el-button>
        </div>
      </div>
    </div>

    <!-- 主要内容区 -->
    <div class="main-content">
      <el-row :gutter="20">
        <!-- 编辑区域 -->
        <el-col :xs="24" :sm="24" :md="24">
          <el-card class="editor-card" shadow="hover">
            <!-- 标题输入框 -->
            <div class="title-input-container">
              <el-input
                v-model="form.title"
                placeholder="请输入文章标题..."
                class="title-input"
                maxlength="100"
                show-word-limit
              ></el-input>
            </div>

            <!-- 工具栏 -->
            <div class="editor-toolbar">
              <el-tooltip content="设置文章属性" placement="top">
                <el-button type="text" @click="openSettingsDialog">
                  <i class="el-icon-setting"></i> 文章设置
                </el-button>
              </el-tooltip>
              <el-tooltip content="上传图片" placement="top">
                <el-button type="text">
                  <i class="el-icon-picture"></i> 插入图片
                </el-button>
              </el-tooltip>
              <el-tooltip content="全屏编辑" placement="top">
                <el-button type="text" @click="toggleFullscreen">
                  <i class="el-icon-full-screen"></i> 全屏编辑
                </el-button>
              </el-tooltip>
            </div>

            <!-- Markdown编辑器 -->
            <v-md-editor
              class="md-editor"
              v-model="form.content"
              height="600px"
              :include-level="[1, 2, 3, 4]"
              @upload-image="handleUploadImage"
            ></v-md-editor>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <!-- 文章设置对话框 -->
    <el-dialog
      title="文章设置"
      :visible.sync="dialogVisible"
      width="800px"
      @closed="resetMetaForm"
      center
      custom-class="settings-dialog"
    >
      <el-form ref="metaForm" :model="formMeta" :rules="metaRules" label-width="100px">
        <el-row :gutter="20">
          <!-- 左侧设置 -->
          <el-col :xs="24" :sm="12">
            <el-card class="settings-card" shadow="never">
              <div slot="header" class="settings-card-header">
                <i class="el-icon-document"></i> 基本设置
              </div>
              
              <!-- 文章类型 -->
              <el-form-item label="文章类型" prop="article_type">
                <el-radio-group v-model="formMeta.article_type" size="medium">
                  <el-radio-button :label="1">
                    <i class="el-icon-notebook-1"></i> 博文
                  </el-radio-button>
                  <el-radio-button :label="2">
                    <i class="el-icon-chat-dot-square"></i> 问答
                  </el-radio-button>
                </el-radio-group>
              </el-form-item>

              <!-- 来源 -->
              <el-form-item label="文章来源" prop="source">
                <el-radio-group v-model="formMeta.source" size="medium">
                  <el-radio :label="2">原创</el-radio>
                  <el-radio :label="1">转载</el-radio>
                  <el-radio :label="3">翻译</el-radio>
                </el-radio-group>
                
                <el-input
                  v-if="formMeta.source !== 2"
                  v-model="formMeta.source_url"
                  placeholder="请输入原文链接"
                  prefix-icon="el-icon-link"
                  style="margin-top: 12px"
                />
              </el-form-item>

              <!-- 分类 -->
              <el-form-item label="文章分类" prop="category_id">
                <el-select
                  v-model="formMeta.category_id"
                  placeholder="请选择分类"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in categories"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-card>
          </el-col>
          
          <!-- 右侧设置 -->
          <el-col :xs="24" :sm="12">
            <el-card class="settings-card" shadow="never">
              <div slot="header" class="settings-card-header">
                <i class="el-icon-reading"></i> 阅读设置
              </div>
              
              <!-- 阅读类型 -->
              <el-form-item label="阅读类型" prop="read_type">
                <el-select
                  v-model="formMeta.read_type"
                  placeholder="请选择阅读类型"
                  style="width: 100%"
                >
                  <el-option
                    v-for="type in readTypes"
                    :key="type.value"
                    :label="type.label"
                    :value="type.value"
                  />
                </el-select>
                
                <div v-if="formMeta.read_type === 2" class="pay-settings">
                  <el-form-item label="支付金额" label-width="80px">
                    <el-input-number
                      v-model="formMeta.pay_amount"
                      :min="0"
                      :step="100"
                      controls-position="right"
                      style="width: 180px"
                    />
                    <span class="unit-label">分</span>
                  </el-form-item>
                  
                  <el-form-item label="支付方式" label-width="80px">
                    <el-select
                      v-model="formMeta.pay_way"
                      placeholder="支付方式"
                      style="width: 180px"
                    >
                      <el-option label="微信H5" value="wx_h5" />
                      <el-option label="微信JSAPI" value="wx_jsapi" />
                      <el-option label="微信Native" value="wx_native" />
                    </el-select>
                  </el-form-item>
                </div>
              </el-form-item>

              <!-- 标签 -->
              <el-form-item label="文章标签">
                <el-select
                  v-model="formMeta.tags"
                  multiple
                  filterable
                  allow-create
                  default-first-option
                  placeholder="请选择或创建标签"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in allTags"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-card>
          </el-col>
        </el-row>
        
        <!-- 封面设置 -->
        <el-card class="settings-card cover-card" shadow="never">
          <div slot="header" class="settings-card-header">
            <i class="el-icon-picture"></i> 封面设置
          </div>
          
          <el-row>
            <el-col :span="24">
              <el-form-item label="文章封面" prop="picture">
                <div class="cover-upload-container">
                  <el-upload
                    :action="IMAGE_API.POST_IMAGE"
                    list-type="picture-card"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove"
                    :on-success="handleCoverSuccess"
                    :limit="1"
                    :file-list="coverFileList"
                  >
                    <div class="upload-placeholder">
                      <i class="el-icon-plus"></i>
                      <div class="upload-hint">上传封面图</div>
                    </div>
                  </el-upload>

                  <div class="cover-tip">
                    <p>推荐尺寸: 1200 x 675px</p>
                    <p>优质的封面能够吸引更多阅读</p>
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
        
        <!-- 摘要设置 -->
        <el-card class="settings-card" shadow="never">
          <div slot="header" class="settings-card-header">
            <i class="el-icon-document-copy"></i> 摘要设置
          </div>
          
          <el-row>
            <el-col :span="24">
              <el-form-item label="文章摘要" prop="summary">
                <div class="summary-container">
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="formMeta.summary"
                    placeholder="请输入文章摘要，简洁明了地描述文章内容"
                    maxlength="300"
                    show-word-limit
                  />
                  <el-button
                    type="primary"
                    class="extract-btn"
                    size="small"
                    plain
                    @click="handleExtractSummary"
                  >
                    <i class="el-icon-magic-stick"></i> 智能提取摘要
                  </el-button>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>

        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitMetaForm">确 定</el-button>
        </div>
      </el-form>
    </el-dialog>

    <!-- 图片预览弹窗 -->
    <el-dialog :visible.sync="dialogVisibleimage" custom-class="image-preview-dialog">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    
    <!-- 文章预览弹窗 -->
    <el-dialog
      title="文章预览"
      :visible.sync="previewVisible"
      width="800px"
      custom-class="preview-dialog"
    >
      <div class="preview-container">
        <h1 class="preview-title">{{ form.title || '无标题文章' }}</h1>
        <div class="preview-meta">
          <span class="preview-category">{{ getCategoryName(formMeta.category_id) }}</span>
          <span class="preview-date">{{ getCurrentDate() }}</span>
        </div>
        <div class="preview-cover" v-if="formMeta.picture">
          <img :src="formMeta.picture" alt="文章封面" />
        </div>
        <div class="preview-content markdown-body">
          <v-md-preview :text="form.content"></v-md-preview>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/axios/api";
import { ARTICLE_API } from "@/api/article-api";
import store from "@/store";
import { IMAGE_API } from "@/api/image-api";

export default {
  computed: {
    IMAGE_API() {
      return IMAGE_API;
    },
    coverFileList() {
      return this.formMeta.picture
        ? [{ url: this.formMeta.picture, name: "封面图" }]
        : [];
    }
  },
  data() {
    return {
      isFullscreen: false,
      previewVisible: false,
      dialogImageUrl: "",
      dialogVisibleimage: false,
      disabled: false,
      dialogVisible: false,
      form: {
        title: "",
        content: ""
      },
      formMeta: {
        article_type: 1,
        source: 2,
        source_url: "",
        category_id: 1,
        read_type: 0,
        pay_amount: 0,
        pay_way: "wx_h5",
        topping_stat: 0,
        cream_stat: 0,
        tags: [],
        picture: "",
        summary: ""
      },
      categories: [
        { value: 1, label: "后端" },
        { value: 2, label: "前端" },
        { value: 3, label: "Github" },
        { value: 4, label: "MySQL" },
        { value: 5, label: "人工智能" },
        { value: 6, label: "开发工具" },
        { value: 7, label: "代码人生" },
        { value: 8, label: "阅读" },
        { value: 9, label: "算法" }
      ],
      allTags: [
        { label: "Vue", value: "1" },
        { label: "React", value: "2" },
        { label: "Node.js", value: "3" },
        { label: "Spring Boot", value: "4" },
        { label: "机器学习", value: "5" }
      ],
      readTypes: [
        { value: 0, label: "直接阅读" },
        { value: 1, label: "登录阅读" },
        { value: 2, label: "付费阅读" },
        { value: 3, label: "星球阅读" }
      ],
      metaRules: {
        source_url: [
          {
            validator: (rule, value, callback) => {
              if ([1, 3].includes(this.formMeta.source) && !value) {
                callback(new Error("请填写原文链接"));
              } else {
                callback();
              }
            }
          }
        ],
        pay_amount: [
          {
            validator: (rule, value, callback) => {
              if (this.formMeta.read_type === 2) {
                if (value === null || value === undefined || value === "") {
                  callback(new Error("请输入付费金额"));
                } else if (value < 0) {
                  callback(new Error("金额不能为负数"));
                } else {
                  callback();
                }
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"]
          }
        ],
        category_id: [
          { required: true, message: "请选择文章分类", trigger: "change" }
        ],
        read_type: [
          { required: true, message: "请选择阅读类型", trigger: "change" }
        ],
        summary: [
          { required: true, message: "请输入文章摘要", trigger: "blur" },
          { max: 256, message: "摘要不能超过256个字符", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    openSettingsDialog() {
      this.dialogVisible = true;
    },
    previewArticle() {
      if (!this.form.title.trim()) {
        this.$message.warning("请先输入文章标题");
        return;
      }
      
      if (!this.form.content.trim()) {
        this.$message.warning("请先输入文章内容");
        return;
      }
      
      this.previewVisible = true;
    },
    saveAsDraft() {
      this.$message.success("文章已保存为草稿");
      // 实际实现中应调用API保存草稿
    },
    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen;
      const editorCard = document.querySelector('.editor-card');
      
      if (this.isFullscreen) {
        editorCard.classList.add('fullscreen');
      } else {
        editorCard.classList.remove('fullscreen');
      }
    },
    getCategoryName(categoryId) {
      const category = this.categories.find(item => item.value === categoryId);
      return category ? category.label : '未分类';
    },
    getCurrentDate() {
      const now = new Date();
      return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`;
    },
    handleSubmit() {
      if (!this.form.title.trim()) {
        this.$message.error("请输入标题");
        return;
      }
      if (!this.form.content.trim()) {
        this.$message.error("请输入文章内容");
        return;
      }
      this.dialogVisible = true;
    },
    handleExtractSummary() {
      const rawContent = this.form.content.replace(/[#*`~]/g, "");
      this.formMeta.summary = rawContent.substring(0, 256);
      this.$message.success("已提取文章摘要");
    },
    submitMetaForm() {
      this.$refs.metaForm.validate(valid => {
        if (valid) {
          // 显示发布中的加载状态
          const loading = this.$loading({
            lock: true,
            text: '正在发布...',
            spinner: 'el-icon-loading',
            background: 'rgba(255, 255, 255, 0.7)'
          });
          
          const postData = {
            userid: store.state.user.id,
            ...this.form,
            ...this.formMeta,
            tags: this.formMeta.tags.join(',')
          };

          // 调用API提交数据
          console.log("提交数据:", postData);
          api
            .post(ARTICLE_API.POST_ARTICLE, postData)
            .then(res => {
              loading.close();
              this.$message.success("发布成功");
              this.dialogVisible = false;
              this.resetForm();
              this.$router.push({
                name: "information",
                query: {
                  id: res
                }
              });
            })
            .catch(err => {
              loading.close();
              this.$message.error("发布失败: " + err.message);
            });
        } else {
          this.$message.warning("请完善文章设置信息");
        }
      });
    },
    handleCoverSuccess(res) {
      console.log(res);
      this.formMeta.picture = res;
      this.$message.success("封面上传成功");
    },
    handleUploadImage(event, insertImage) {
      const file = event.file;
      const formData = new FormData();
      formData.append("file", file);

      // 显示上传中的加载状态
      const loading = this.$loading({
        lock: true,
        text: '图片上传中...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
      });

      this.$axios
        .post("/api/upload", formData)
        .then(res => {
          loading.close();
          insertImage({
            url: res.data.url,
            desc: res.data.name
          });
          this.$message.success("图片上传成功");
        })
        .catch(() => {
          loading.close();
          this.$message.error("图片上传失败");
        });
    },
    resetMetaForm() {
      if (this.$refs.metaForm) {
        this.$refs.metaForm.resetFields();
      }
    },
    resetForm() {
      this.form = { title: "", content: "" };
      this.resetMetaForm();
      this.formMeta = {
        article_type: 1,
        source: 2,
        source_url: "",
        category_id: 1,
        read_type: 0,
        pay_amount: 0,
        pay_way: "wx_h5",
        topping_stat: 0,
        cream_stat: 0,
        tags: [],
        picture: "",
        summary: ""
      };
    },
    handleRemove() {
      this.formMeta.picture = "";
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisibleimage = true;
    }
  }
};
</script>

<style scoped>
.article-create-container {
  background-color: #f5f7fa;
  min-height: 100vh;
}

/* 页面头部 */
.page-header {
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
  padding: 16px 0;
  position: sticky;
  top: 0;
  z-index: 100;
}

.header-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-title {
  font-size: 22px;
  color: #303133;
  margin: 0;
  font-weight: 600;
}

.page-actions {
  display: flex;
  gap: 12px;
}

/* 主要内容区 */
.main-content {
  max-width: 1200px;
  margin: 24px auto;
  padding: 0 20px;
}

/* 编辑器卡片 */
.editor-card {
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s;
}

.editor-card.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  border-radius: 0;
  margin: 0;
  padding: 20px;
}

.title-input-container {
  margin-bottom: 20px;
}

.title-input :deep(input) {
  font-size: 24px;
  height: 56px;
  border: none;
  border-bottom: 1px solid #ebeef5;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
  font-weight: 600;
}

.title-input :deep(input):focus {
  border-color: #409EFF;
}

.editor-toolbar {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  padding: 8px 0;
  border-bottom: 1px solid #ebeef5;
}

.md-editor {
  border-radius: 4px;
}

/* 设置对话框 */
:deep(.settings-dialog) {
  border-radius: 8px;
}

.settings-card {
  margin-bottom: 20px;
  border-radius: 8px;
  transition: all 0.3s;
}

.settings-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05) !important;
}

.settings-card-header {
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #303133;
}

.settings-card-header i {
  margin-right: 8px;
  color: #409EFF;
}

/* 封面上传 */
.cover-upload-container {
  display: flex;
  align-items: flex-start;
}

.cover-tip {
  margin-left: 20px;
  color: #909399;
  font-size: 13px;
}

.cover-tip p {
  margin: 5px 0;
}

.upload-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #909399;
}

.upload-hint {
  margin-top: 8px;
  font-size: 12px;
}

/* 摘要 */
.summary-container {
  position: relative;
}

.extract-btn {
  position: absolute;
  right: 10px;
  top: 10px;
}

/* 付费设置 */
.pay-settings {
  background-color: #f8f9fa;
  padding: 12px;
  margin-top: 12px;
  border-radius: 4px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.05);
}

.unit-label {
  margin-left: 8px;
  color: #606266;
}

/* 对话框底部 */
.dialog-footer {
  text-align: right;
  margin-top: 20px;
}

/* 预览弹窗 */
:deep(.preview-dialog) {
  border-radius: 8px;
}

.preview-container {
  padding: 20px;
}

.preview-title {
  font-size: 28px;
  margin-bottom: 16px;
  color: #303133;
}

.preview-meta {
  display: flex;
  gap: 16px;
  margin-bottom: 20px;
  color: #909399;
  font-size: 14px;
}

.preview-category {
  background-color: #ecf5ff;
  color: #409EFF;
  padding: 2px 8px;
  border-radius: 4px;
}

.preview-cover {
  margin-bottom: 24px;
  border-radius: 8px;
  overflow: hidden;
}

.preview-cover img {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
}

/* 响应式调整 */
@media screen and (max-width: 768px) {
  .page-header {
    padding: 12px 0;
  }
  
  .header-content {
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
  }
  
  .page-actions {
    width: 100%;
    justify-content: space-between;
  }
  
  .title-input :deep(input) {
    font-size: 20px;
    height: 48px;
  }
  
  .cover-upload-container {
    flex-direction: column;
  }
  
  .cover-tip {
    margin-left: 0;
    margin-top: 12px;
  }
  
  .extract-btn {
    position: static;
    margin-top: 12px;
    width: 100%;
  }
}
</style>